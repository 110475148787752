import React from 'react'
import { graphql } from 'gatsby'
import { RichText, Date } from 'prismic-reactjs'
import Layout from '../components/Layout' 
import Header from '../components/Header'
import Container from '../elements/Container'
import Content from '../elements/Content'

// Display the title, date, and content of the Post
const PostBody = ({ post }) => {  
  // Store and format the blog post's publication date
  let postDate = Date(post.date);
  postDate = postDate ? 
    new Intl.DateTimeFormat('de-DE', {
      month: 'short', 
      day: '2-digit', 
      year: 'numeric'
    }).format(postDate) :
    '';

  return (
    <div>
      <Header title={post.title[0].text} info={postDate} imageUrl={post.titleImage.url}/>
      <Container type="article">
        <Content>
          {RichText.render(post.intro)}
          {RichText.render(post.content)}
          <h3 style={{ textAlign: 'center'}}> Zeitraum: {RichText.asText(post.timespan)}</h3>
          <h3 style={{ textAlign: 'center', fontSize: '2.0rem'}}>{RichText.asText(post.emojis)}</h3>
        </Content>
      </Container>
    </div>
  );
}

export default (props) => {
  // Define the Post content returned from Prismic
  const doc = props.data.prismic.allPosts.edges.slice(0,1).pop();

  if(!doc) return null;

  return(
    <Layout>
      <PostBody post={ doc.node } />
    </Layout>
  )
}

// Query for the Blog Post content in Prismic
export const query = graphql`
query BlogPostQuery($uid: String) {
  prismic{
    allPosts(uid: $uid){
      edges{
        node{
          _meta{
            id
            uid
          }
          title
          titleImage
          intro
          date
          timespan
          emojis
          content
        }
      }
    }
  }
}
`
